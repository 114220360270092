.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.LoginMobile {
  overflow: auto;
  height: 50vh;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}

.LoginDesktop {
  overflow: auto;
  height: 60%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20%;
}

.SlackLanding {
  overflow: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.PrivacyLanding {
  text-align: left;
  width:80%;
  margin-left:10%; 
  padding: 20px;
}

.PrivacyLandingContent {
  padding: 5px 10px;
}

.InstallLanding {
  text-align: left;
  width:80%;
  margin-left:10%; 
  padding: 20px;
}

.InstallLandingContent {
  padding: 5px 10px;
}

.List-header {
  justify-content: space-between;
  
}

.App-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: black;
  text-align: center;
}